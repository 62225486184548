// React
import React from "react";
import { useEffect, useState } from 'react';
// 外部ライブラリ
import axios from 'axios'
import { motion } from "framer-motion"
import { useNavigate, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { validateName, validateSubject, validateEmail, validateDetails, validateAgreement } from 'modules/validate'
import { activateHoverCursor, deactivateHoverCursor } from "modules/mouseStalker"
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import PrimaryButton from "components/ui/PrimaryButton"
// 型定義
import { Errors, Values } from './Types'
// 定数
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
// 画像
// その他
import InputDummyData from './InputDummyData' // ダミーデータ入力用

type Props = {
}

export default function Index(props: Props) {

  /*=======================================
    初期化
  =======================================*/

  const navigate = useNavigate(); // ページ遷移用
  const location = useLocation();

  const [errorMessages, setErrorMessages] = useState(initErrorMessage()); // エラー項目一覧格納用
  const [inputValues, setInputValues] = useState(initValues()); // 値格納用

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  // エラーメッセージオブジェクトの初期化
  function initErrorMessage(): Errors {
    return {
      errorItems: [] as string[],
      name: "",
      email: "",
      subject: "",
      details: "",
      agreement: "",
    }
  }

  // フォームの値オブジェクトの初期化
  function initValues(): Values {
    if (location.state?.values) {
      return location.state.values
    }
    return {
      name: "",
      email: "",
      subject: "",
      details: "",
      agreement: false,
    }
  }

  // テキストエリア自動伸縮
  function handleTextareaInput(e: any) {
    let offset = 16
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + offset}px`;
  }

  /*=======================================
    バリデーション
  =======================================*/

  // 全てのバリデーションを実行
  function isValidValue(values: Values): boolean {
    const errors: Errors = initErrorMessage();
    validateName(values.name, errors);
    validateEmail(values.email, errors);
    validateSubject(values.subject, errors);
    validateDetails(values.details, errors);
    validateAgreement(values.agreement, errors);
    setErrorMessages(errors);
    return !Boolean(errors.errorItems.length)
  }

  function handleSubmit(e: any) {

    e.preventDefault();

    const values: Values = {
      subject: qs("#subject").value,
      name: qs("#name").value,
      email: qs("#email").value,
      details: qs("#details").value,
      agreement: qs("#agreement").checked,
    };

    const isValid = isValidValue(values)

    if (isValid) {
      axios({
        method: 'post',
        url: "/",
        timeout: 5000,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: encode({ "form-name": "contact", "name": values.name, "subject": values.subject, "email": values.email, "details": values.details })
      })
        .then(function (res) {
          navigate('/contact/submitted')
        })
        .catch(function (e) {
          alert("お問い合わせの送信に失敗しました。大変恐れ入りますが、しばらく時間を置いてもう一度お試しください。");
        })
    } else {
      scrollToSelector("#js__error_existence");
    }

  };
  // フォームで送る値をエンコード
  function encode(data: any) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleFocus(selector: string) {
    addClass(qs(selector), "active");
  }
  function handleBlur(selector: string) {
    removeClass(qs(selector), "active");
  }

  return (
    <>
      <Helmet>
        <title>{`Contact｜${SITE_TITLE}`}</title>
        <meta name="description" content={`お問い合わせ｜${SITE_TITLE}`} />
        <meta property="og:url" content={SITE_URL_BASE + "/contact"} />
        <meta property="og:title" content={`Contact｜${SITE_TITLE}`} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >

        <main id="p__contact" className="l__main_content">

          <PrimaryHeading>Contact</PrimaryHeading>

          <div className="contact_inner c__js_fade_delay">

            <p className="contact_description js__delay_child delay0">
              お仕事のご依頼、ご相談やお問い合わせは下記フォームよりお願い致します。<br />
              通常お問合せを頂いてから、２〜３日以内にお返事させて頂きます。<br />
              一部回答できないと判断した場合、お返事は致しかねますので予めご了承下さい。
            </p>

            {/* ダミーデータ入力用 */}
            {/* <InputDummyData /> */}

            <form name="contact" method="post" className=" js__delay_child delay1">

              <p id="js__error_existence" className={"c__error_message all_error_messages" + (errorMessages.errorItems.length ? " active" : "")}>
                入力内容をご確認ください。<br />
                {errorMessages.errorItems.map((error: any, index: number) => {
                  return (
                    <span key={index} className="u__error_list">{"・" + error}</span>
                  )
                })}
              </p>

              <p id="nameErrorMessage" className={"c__error_message" + (errorMessages.name ? " active" : "")}>{errorMessages.name}</p>
              <div className="input_wrapper textbox js__name_wrapper">
                <label className="c__contact_label required">お名前</label>
                <input type="text" id="name" name="name" className="c__contact_textbox" maxLength={60} placeholder="例）紡木吏佐" defaultValue={inputValues.name} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} onFocus={() => handleFocus(".js__name_wrapper")} onBlur={() => handleBlur(".js__name_wrapper")} />
              </div>


              <p id="emailErrorMessage" className={"c__error_message" + (errorMessages.email ? " active" : "")}>{errorMessages.email}</p>
              <div className="input_wrapper textbox js__email_wrapper">
                <label className="c__contact_label required">メールアドレス</label>
                <input type="email" id="email" name="email" className="c__contact_textbox" maxLength={256} placeholder="例）xxx@xxx.com" data-conv-half-alphanumeric="true" defaultValue={inputValues.email} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} onFocus={() => handleFocus(".js__email_wrapper")} onBlur={() => handleBlur(".js__email_wrapper")} />
              </div>

              <p id="subjectErrorMessage" className={"c__error_message" + (errorMessages.subject ? " active" : "")}>{errorMessages.subject}</p>
              <div className="input_wrapper textbox js__subject_wrapper">
                <label className="c__contact_label">件名</label>
                <input type="text" id="subject" name="subject" className="c__contact_textbox" maxLength={60} placeholder="例）声の出演に関してのご相談" defaultValue={inputValues.subject} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} onFocus={() => handleFocus(".js__subject_wrapper")} onBlur={() => handleBlur(".js__subject_wrapper")} />
              </div>

              <p id="detailsErrorMessage" className={"c__error_message" + (errorMessages.details ? " active" : "")}>{errorMessages.details}</p>
              <div className="input_wrapper textbox js__details_wrapper">
                <label className="c__contact_label required">お問い合わせ内容</label>
                <textarea id="details" name="details" className="c__contact_textarea" maxLength={1000} placeholder="こちらに詳しいお問い合わせ内容をご入力ください&#10;例）声の出演に関しまして" defaultValue={inputValues.details} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} onFocus={() => handleFocus(".js__details_wrapper")} onBlur={() => handleBlur(".js__details_wrapper")} onInput={(e) => handleTextareaInput(e)} />
              </div>

              <p id="agreementErrorMessage" className={"c__error_message" + (errorMessages.agreement ? " active" : "")}>{errorMessages.agreement}</p>
              <div className="input_wrapper agreement_wrapper wide_margin">
                <label className="c__contact_label required">プライバシーポリシー</label>
                <div className="radio_buttons_wrapper">
                  <input type="checkbox" id="agreement" name="agreement" value="agreed" className="c__contact_checkbox" defaultChecked={inputValues.agreement} />
                  <label className="agreement_label" htmlFor="agreement" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}><a href="/terms" target="_blank" rel="noopener noreferrer">内容を確認</a>し、同意する</label>
                </div>
              </div>

              <PrimaryButton path="" mode="button" type="submit" id="submit" onClick={(e: any) => handleSubmit(e)}>Send</PrimaryButton >

            </form>
          </div>

        </main>


      </motion.div>
    </>
  );
}